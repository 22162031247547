<template>
    <img v-if="country"
         :src="flagImg(country, game_group)"
         :title="country"
         class="mini-flag"/>
</template>

<script>

export default {
    props: {
        country: String,
        game_group: String,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        flagImg(countryCode, gameGroup) {
            if ('BOT_AI' === gameGroup) {
                return '/img/wizarre-flag-bg.png';
            }
            return `https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/${countryCode.toLowerCase()}.svg`;
        },
    }
};
</script>

<style lang="scss" scoped>
.mini-flag {
    width: 18px;
    height: 12px;
    object-fit: fill;
}
</style>
