<template>
    <img v-if="integration_name"
         :src="`/img/dashboard/${integration_name}-icon.png`"
         :title="integration_name"
         class="integration-icon"/>
</template>

<script>

export default {
    props: {
        integration_name: String,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {}
};
</script>

<style lang="scss" scoped>
.integration-icon {
    width: 12px;
}
</style>
