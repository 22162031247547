var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", order: "1", xl: "7" } },
            [
              _c("h4", [
                _vm._v(
                  "Matchmaking Queue [" +
                    _vm._s(_vm.queuedPlayerItems.length) +
                    "x]"
                ),
              ]),
              _c("v-data-table", {
                attrs: {
                  headers: [
                    { text: "Username", value: "usernames" },
                    { text: "Group", value: "col6" },
                    { text: "RankLv", value: "col2" },
                    { text: "FriendCode", value: "col4" },
                    { text: "MatchmakingType", value: "col5" },
                    { text: "Version", value: "col8" },
                    { text: "InQueue", value: "col7" },
                    { text: "IP", value: "col9" },
                  ],
                  items: _vm.queuedPlayerItems,
                  "items-per-page": 50,
                  dense: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.col7",
                    fn: function ({ item }) {
                      return [
                        _vm.timersResetSwitch
                          ? _c("timer", {
                              attrs: {
                                "count-upwards": true,
                                "deadline-timestamp": item.col7,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.usernames",
                    fn: function ({ item }) {
                      return [
                        _c("integration-icon", {
                          attrs: { integration_name: item.integration_name },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(item.username) +
                            " (" +
                            _vm._s(item.level) +
                            " lv) "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.col9",
                    fn: function ({ item }) {
                      return [
                        _c("flag-icon", {
                          attrs: {
                            country: item.playerIpCountry,
                            game_group: item.col6,
                          },
                        }),
                        _vm._v(" " + _vm._s(item.col9) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("hr"),
              _c("h4", [
                _vm._v(
                  "In progress [" +
                    _vm._s(_vm.inProgressMatchesItems.length) +
                    "x]"
                ),
              ]),
              _c("v-data-table", {
                attrs: {
                  headers: [
                    { text: "Started ago", value: "started" },
                    { text: "Usernames", value: "usernames" },
                    { text: "Groups", value: "game_groups" },
                    { text: "Game Mode", value: "matchmakingType" },
                    { text: "OS", value: "platforms" },
                    { text: "Version", value: "gameVersions" },
                    { text: "IPs", value: "ips" },
                    { text: "Countries", value: "countries" },
                  ],
                  items: _vm.inProgressMatchesItems,
                  "items-per-page": 50,
                  dense: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.usernames",
                    fn: function ({ item }) {
                      return [
                        _c("integration-icon", {
                          attrs: {
                            integration_name: item.integration_names[0],
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(item.usernames[0]) +
                            " (" +
                            _vm._s(item.levels[0]) +
                            "lv) | "
                        ),
                        _c("integration-icon", {
                          attrs: {
                            integration_name: item.integration_names[1],
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(item.usernames[1]) +
                            " (" +
                            _vm._s(item.levels[1]) +
                            "lv) "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.started",
                    fn: function ({ item }) {
                      return [
                        _vm.timersResetSwitch
                          ? _c("timer", {
                              attrs: {
                                "count-upwards": true,
                                "deadline-timestamp": item.started,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.platforms",
                    fn: function ({ item }) {
                      return [
                        _c("img", {
                          staticClass: "icon-os",
                          attrs: {
                            src:
                              "/img/icons/os/" +
                              _vm.platformParse(item.platforms[0]) +
                              "_48.png",
                            title: _vm.platformParse(item.platforms[0]),
                          },
                        }),
                        _c("img", {
                          staticClass: "icon-os",
                          attrs: {
                            src:
                              "/img/icons/os/" +
                              _vm.platformParse(item.platforms[1]) +
                              "_48.png",
                            title: _vm.platformParse(item.platforms[1]),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.countries",
                    fn: function ({ item }) {
                      return [
                        _c("flag-icon", {
                          attrs: {
                            country: item.countries[0],
                            game_group: item.game_groups.split("|")[0],
                          },
                        }),
                        _c("span", [_vm._v("|")]),
                        _c("flag-icon", {
                          attrs: {
                            country: item.countries[1],
                            game_group: item.game_groups.split("|")[1],
                          },
                        }),
                        _c("span", [_vm._v("|" + _vm._s(item.host_server))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", order: "2", xl: "5" } },
            [
              _c("h4", [_vm._v("Finished match reason")]),
              _c("v-data-table", {
                attrs: {
                  headers: [
                    { text: "Reason", value: "reason" },
                    { text: "Last 1 day (avg time)", value: "d1_count" },
                    { text: "Last 7 days (avg time)", value: "d7_count" },
                    { text: "Last 30 days (avg time)", value: "d30_count" },
                  ],
                  items: _vm.finishedMatchReason,
                  "items-per-page": 50,
                  dense: "",
                  "hide-default-footer": "",
                },
              }),
              _c("hr"),
              _c("h4", [
                _vm._v(
                  "Menu Idlers [" + _vm._s(_vm.idlePlayerItems.length) + "x]"
                ),
              ]),
              _c("v-data-table", {
                attrs: {
                  headers: [
                    { text: "Username", value: "col1" },
                    { text: "Group", value: "col3" },
                    { text: "Time", value: "col2" },
                    { text: "Type", value: "col4" },
                    { text: "Ip", value: "col6" },
                  ],
                  items: _vm.idlePlayerItems,
                  "items-per-page": 50,
                  dense: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.col6",
                    fn: function ({ item }) {
                      return [
                        _c("flag-icon", {
                          attrs: {
                            country: item.playerCountryCode,
                            game_group: item.col3,
                          },
                        }),
                        _vm._v(" " + _vm._s(item.col6) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("hr"),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", order: "3" } },
            [
              _c("h4", [_vm._v("Matches history")]),
              _c("v-data-table", {
                attrs: {
                  headers: [
                    { text: "Started ago", value: "started" },
                    { text: "Reasons", value: "reasons" },
                    { text: "OS", value: "platforms" },
                    { text: "Version", value: "game_versions" },
                    { text: "Usernames", value: "usernames" },
                    { text: "Groups", value: "game_groups" },
                    { text: "BattleTime", value: "battle_time" },
                    { text: "IPs", value: "ips" },
                    { text: "Countries", value: "countries" },
                    { text: "SCRL earned", value: "scrl_earned" },
                  ],
                  items: _vm.lastMatchesItems,
                  "items-per-page": 50,
                  dense: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.started",
                    fn: function ({ item }) {
                      return [
                        _vm.timersResetSwitch
                          ? _c("timer", {
                              attrs: {
                                "count-upwards": true,
                                "deadline-timestamp": item.started,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.usernames",
                    fn: function ({ item }) {
                      return [
                        _c("integration-icon", {
                          attrs: {
                            integration_name: item.integration_names[0],
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(item.usernames[0]) +
                            " (" +
                            _vm._s(item.levels[0]) +
                            " lv) | "
                        ),
                        _c("integration-icon", {
                          attrs: {
                            integration_name: item.integration_names[1],
                          },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(item.usernames[1]) +
                            " (" +
                            _vm._s(item.levels[1]) +
                            " lv) "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.platforms",
                    fn: function ({ item }) {
                      return [
                        _c("img", {
                          staticClass: "icon-os",
                          attrs: {
                            src:
                              "/img/icons/os/" +
                              _vm.platformParse(item.platforms[0]) +
                              "_48.png",
                            title: _vm.platformParse(item.platforms[0]),
                          },
                        }),
                        _c("img", {
                          staticClass: "icon-os",
                          attrs: {
                            src:
                              "/img/icons/os/" +
                              _vm.platformParse(item.platforms[1]) +
                              "_48.png",
                            title: _vm.platformParse(item.platforms[1]),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.countries",
                    fn: function ({ item }) {
                      return [
                        _c("flag-icon", {
                          attrs: {
                            country: item.countries[0],
                            game_group: item.game_groups.split("|")[0],
                          },
                        }),
                        _c("span", [_vm._v("|")]),
                        _c("flag-icon", {
                          attrs: {
                            country: item.countries[1],
                            game_group: item.game_groups.split("|")[1],
                          },
                        }),
                        _c("span", [_vm._v("|" + _vm._s(item.host_server))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("hr"),
              _c("h4", [
                _vm._v(" Bugs [" + _vm._s(_vm.bugItems.length) + "x]"),
              ]),
              _c("v-data-table", {
                attrs: {
                  headers: [
                    { text: "Id", value: "col1" },
                    { text: "Message", value: "col2" },
                    { text: "Username", value: "col3" },
                    { text: "game_version", value: "col4" },
                    { text: "platform", value: "col6" },
                    { text: "created_on", value: "col5" },
                  ],
                  items: _vm.bugItems,
                  "items-per-page": 50,
                  dense: "",
                },
              }),
              _c("hr"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }