<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" order="1" xl="7">
                <h4>Matchmaking Queue [{{ queuedPlayerItems.length }}x]</h4>
                <v-data-table
                    :headers="[
                                {text:'Username',value:'usernames'},
                                {text:'Group',value:'col6'},
                                {text:'RankLv',value:'col2'},
                                {text:'FriendCode',value:'col4'},
                                {text:'MatchmakingType',value:'col5'},
                                {text:'Version',value:'col8'},
                                {text:'InQueue',value:'col7'},
                                {text:'IP',value:'col9'},
                                ]"
                    :items="queuedPlayerItems"
                    :items-per-page=50
                    dense
                >
                    <template v-slot:item.col7="{ item }">
                        <timer v-if="timersResetSwitch" :count-upwards="true"
                               :deadline-timestamp="item.col7"></timer>
                    </template>
                    <template v-slot:item.usernames="{ item }">
                        <integration-icon :integration_name="item.integration_name"/>
                        {{ item.username }} ({{ item.level }} lv)
                    </template>

                    <template v-slot:item.col9="{ item }">
                        <flag-icon :country="item.playerIpCountry" :game_group="item.col6"/>
                        {{ item.col9 }}
                    </template>
                </v-data-table>

                <hr/>

                <h4>In progress [{{ inProgressMatchesItems.length }}x]</h4>
                <v-data-table
                    :headers="[
                                {text:'Started ago',value:'started'},
                                {text:'Usernames',value:'usernames'},
                                {text:'Groups',value:'game_groups'},
                                {text:'Game Mode',value:'matchmakingType'},
                                {text:'OS',value:'platforms'},
                                {text:'Version',value:'gameVersions'},
                                {text:'IPs',value:'ips'},
                                {text:'Countries',value:'countries'},
                                ]"
                    :items="inProgressMatchesItems"
                    :items-per-page=50
                    dense
                >
                    <template v-slot:item.usernames="{ item }">
                        <integration-icon :integration_name="item.integration_names[0]"/>
                        {{ item.usernames[0] }} ({{ item.levels[0] }}lv)
                        |
                        <integration-icon :integration_name="item.integration_names[1]"/>
                        {{ item.usernames[1] }} ({{ item.levels[1] }}lv)
                    </template>
                    <template v-slot:item.started="{ item }">
                        <timer v-if="timersResetSwitch" :count-upwards="true"
                               :deadline-timestamp="item.started"></timer>
                    </template>
                    <template v-slot:item.platforms="{ item }">
                        <img :src="'/img/icons/os/'+platformParse(item.platforms[0])+'_48.png'" :title="platformParse(item.platforms[0])"
                             class="icon-os"/>
                        <img :src="'/img/icons/os/'+platformParse(item.platforms[1])+'_48.png'" :title="platformParse(item.platforms[1])"
                             class="icon-os"/>
                    </template>
                    <template v-slot:item.countries="{ item }">
                        <flag-icon :country="item.countries[0]" :game_group="item.game_groups.split('|')[0]"/>
                        <span>|</span>
                        <flag-icon :country="item.countries[1]" :game_group="item.game_groups.split('|')[1]"/>
                        <span>|{{ item.host_server }}</span>
                    </template>
                </v-data-table>

            </v-col>
            <v-col cols="12" order="2" xl="5">
                <h4>Finished match reason</h4>
                <v-data-table
                    :headers="[
                                {text:'Reason',value:'reason'},
                                {text:'Last 1 day (avg time)',value:'d1_count'},
                                {text:'Last 7 days (avg time)',value:'d7_count'},
                                {text:'Last 30 days (avg time)',value:'d30_count'},
                            ]"
                    :items="finishedMatchReason"
                    :items-per-page=50
                    dense
                    hide-default-footer
                >
                </v-data-table>

                <hr/>

                <h4>Menu Idlers [{{ idlePlayerItems.length }}x]</h4>
                <v-data-table
                    :headers="[
                                {text:'Username',value:'col1'},
                                {text:'Group',value:'col3'},
                                {text:'Time',value:'col2'},
                                {text:'Type',value:'col4'},
                                {text:'Ip',value:'col6'},
                                ]"
                    :items="idlePlayerItems"
                    :items-per-page=50
                    dense
                >
                    <template v-slot:item.col6="{ item }">
                        <flag-icon :country="item.playerCountryCode" :game_group="item.col3"/>
                        {{ item.col6 }}
                    </template>
                </v-data-table>

                <!--                <h4>Spectators [{{ allSpectatorItems.length }}x]</h4>-->
                <!--                <v-data-table-->
                <!--                    :headers="[-->
                <!--                                {text:'Username',value:'col1'},-->
                <!--                                {text:'Time',value:'col2'},-->
                <!--                                {text:'Version',value:'col3'},-->
                <!--                                {text:'Status',value:'col4'},-->
                <!--                                ]"-->
                <!--                    :items="allSpectatorItems"-->
                <!--                    :items-per-page=50-->
                <!--                    dense-->
                <!--                >-->
                <!--                </v-data-table>-->

                <hr/>
            </v-col>
            <v-col cols="12" order="3">
                <h4>Matches history</h4>
                <v-data-table
                    :headers="[
                                {text:'Started ago',value:'started'},
                                {text:'Reasons',value:'reasons'},
                                {text:'OS',value:'platforms'},
                                {text:'Version',value:'game_versions'},
                                {text:'Usernames',value:'usernames'},
                                {text:'Groups',value:'game_groups'},
                                {text:'BattleTime',value:'battle_time'},
                                {text:'IPs',value:'ips'},
                                {text:'Countries',value:'countries'},
                                {text:'SCRL earned',value:'scrl_earned'},
                                ]"
                    :items="lastMatchesItems"
                    :items-per-page=50
                    dense
                >
                    <template v-slot:item.started="{ item }">
                        <timer v-if="timersResetSwitch" :count-upwards="true"
                               :deadline-timestamp="item.started"></timer>
                    </template>
                    <template v-slot:item.usernames="{ item }">
                        <integration-icon :integration_name="item.integration_names[0]"/>
                        {{ item.usernames[0] }} ({{ item.levels[0] }} lv)
                        |
                        <integration-icon :integration_name="item.integration_names[1]"/>
                        {{ item.usernames[1] }} ({{ item.levels[1] }} lv)
                    </template>
                    <template v-slot:item.platforms="{ item }">
                        <img :src="'/img/icons/os/'+platformParse(item.platforms[0])+'_48.png'" :title="platformParse(item.platforms[0])"
                             class="icon-os"/>
                        <img :src="'/img/icons/os/'+platformParse(item.platforms[1])+'_48.png'" :title="platformParse(item.platforms[1])"
                             class="icon-os"/>
                    </template>
                    <template v-slot:item.countries="{ item }">
                        <flag-icon :country="item.countries[0]" :game_group="item.game_groups.split('|')[0]"/>
                        <span>|</span>
                        <flag-icon :country="item.countries[1]" :game_group="item.game_groups.split('|')[1]"/>
                        <span>|{{ item.host_server }}</span>
                    </template>
                </v-data-table>

                <hr/>

                <h4> Bugs [{{ bugItems.length }}x]</h4>
                <v-data-table
                    :headers="[
                                {text:'Id',value:'col1'},
                                {text:'Message',value:'col2'},
                                {text:'Username',value:'col3'},
                                {text:'game_version',value:'col4'},
                                {text:'platform',value:'col6'},
                                {text:'created_on',value:'col5'},
                                ]"
                    :items="bugItems"
                    :items-per-page=50
                    dense
                >
                </v-data-table>

                <hr/>


            </v-col>
        </v-row>

    </v-container>
</template>


<script>
import PageFiltersSection from "../components/page-filters-section";
import {apiServiceVue} from "../services/apiService.vue";
import {Bar as BarChartGenerator} from 'vue-chartjs/legacy'
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js'
import Timer from "../components/timer";
import {dbDateToMicroTimestamp, timestampToHoursMinutesSeconds} from "@/helpers/timeHelper";
import IntegrationIcon from "@/components/dashboard/integration-icon";
import FlagIcon from "@/components/dashboard/flag-icon";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
    components: {FlagIcon, IntegrationIcon, Timer, PageFiltersSection, BarChartGenerator},
    props: [],
    data() {
        return {
            timersResetSwitch: true,

            bugItems: [],
            idlePlayerItems: [],
            queuedPlayerItems: [],
            lastMatchesItems: [],
            idleSpectatorItems: [],
            queuedSpectatorItems: [],
            spectatingSpectatorItems: [],
            inProgressMatchesItems: [],
            finishedMatchReason: [],
            intervals: [],
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.fetchGameData();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.fetchGameData();
            });
        }
    },
    destroyed() {
        for (const interval of this.intervals) {
            clearInterval(interval);
        }
    },
    computed: {
        web3Balance() {
            return this.web3.balance;
        },
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        allSpectatorItems() {
            return [
                ...this.idleSpectatorItems,
                ...this.queuedSpectatorItems,
                ...this.spectatingSpectatorItems
            ]
        },
    },
    methods: {
        fetchGameData() {
            for (const interval of this.intervals) {
                clearInterval(interval);
            }

            this.fetchQueue()
            this.intervals.push(
                setInterval(() => {
                    this.fetchQueue();
                    this.resetTimersDisplay();
                }, 2 * 1000) //2sec
            );

            this.fetchIdlers()
            this.intervals.push(
                setInterval(() => {
                    this.fetchIdlers();
                }, 2.5 * 1000) //2.5sec
            );

            this.fetchInProgressMatches();
            this.intervals.push(
                setInterval(() => {
                    this.fetchInProgressMatches();
                }, 3 * 1000) //3sec
            );

            this.fetchLastMatches();
            this.intervals.push(
                setInterval(() => {
                    this.fetchLastMatches();
                }, 10 * 1000) //10sec
            );

            this.fetchBugs();
            this.intervals.push(
                setInterval(() => {
                    this.fetchBugs();
                }, 15 * 60 * 1000) //15min
            );

            this.fetchStatistics();
            this.intervals.push(
                setInterval(() => {
                    this.fetchStatistics();
                }, 15 * 60 * 1000) //15min
            );
        },

        fetchStatistics() {
            apiServiceVue.get(process.env.VUE_APP_GAME_DOMAIN + 'admin/matches/finished')
                .then((res) => {
                    this.finishedMatchReason = [];
                    for (const row of res.data) {
                        row.d1_count = String(row.d1_count) + (row.d1_avg_play_time ? ' (' + timestampToHoursMinutesSeconds(row.d1_avg_play_time) + ')' : '');
                        row.d7_count = String(row.d7_count) + (row.d7_avg_play_time ? ' (' + timestampToHoursMinutesSeconds(row.d7_avg_play_time) + ')' : '');
                        row.d30_count = String(row.d30_count) + (row.d30_avg_play_time ? ' (' + timestampToHoursMinutesSeconds(row.d30_avg_play_time) + ')' : '');
                        this.finishedMatchReason.push(row);
                    }
                });
        },

        fetchIdlers() {
            apiServiceVue.get(process.env.VUE_APP_GAME_DOMAIN + 'admin/idle')
                .then((res) => {
                    this.idlePlayerItems = [];
                    this.idleSpectatorItems = [];
                    for (const row of res.data) {
                        if (row.game_group === 'SPECTATOR') {
                            this.idleSpectatorItems.push(
                                {
                                    col1: row.username,
                                    col2: timestampToHoursMinutesSeconds(row.idle_time),
                                    col3: row.version,
                                    col4: 'IDLE',
                                },
                            )
                        } else {
                            this.idlePlayerItems.push(
                                {
                                    col1: row.username,
                                    col2: timestampToHoursMinutesSeconds(row.idle_time),
                                    col3: row.game_group,
                                    col4: row.type,
                                    col5: row.version,
                                    col6: row.playerIp,
                                    playerCountryCode: row.playerCountryCode
                                },
                            );
                        }
                    }
                });
        },

        fetchBugs() {
            apiServiceVue.get(process.env.VUE_APP_GAME_DOMAIN + 'admin/bugs')
                .then((res) => {
                    this.bugItems = [];
                    for (const row of res.data) {
                        this.bugItems.push(
                            {
                                col1: row.id,
                                col2: row.message,
                                col3: row.username,
                                col4: row.game_version,
                                col5: new Date(row.created_on).toUTCString(),
                                col6: row.platform,
                            },
                        );
                    }
                });
        },

        fetchQueue() {
            apiServiceVue.get(process.env.VUE_APP_GAME_DOMAIN + 'admin/queue')
                .then((res) => {
                    this.queuedPlayerItems = [];
                    this.queuedSpectatorItems = [];
                    for (const row of res.data) {
                        if (row.matchmakingType === 'SPECTATOR') {
                            this.queuedSpectatorItems.push(
                                {
                                    col1: row.username,
                                    col2: timestampToHoursMinutesSeconds(Math.round((Date.now() - row.startedTime) / 1000)),
                                    col3: row.version,
                                    col4: 'QUEUED',
                                },
                            )
                        } else {
                            this.queuedPlayerItems.push(
                                {
                                    username: row.username,
                                    col1: row.uuid,
                                    col2: row.rankLv,
                                    col3: row.roomId,
                                    col4: row.friendCode,
                                    col5: row.matchmakingType,
                                    col6: row.gameGroup,
                                    col7: Math.round(row.startedTime / 1000),
                                    col8: row.version,
                                    col9: row.playerIP,
                                    playerIpCountry: row.playerIPCountry,
                                    integration_name: row.integration_name,
                                    level: row.level,
                                },
                            );
                        }
                    }
                });
        },

        fetchInProgressMatches() {
            apiServiceVue.get(process.env.VUE_APP_GAME_DOMAIN + 'admin/matches/in-progress')
                .then((res) => {
                    this.inProgressMatchesItems = [];
                    this.spectatingSpectatorItems = [];
                    for (const row of res.data) {
                        this.inProgressMatchesItems.push(
                            {
                                started: Math.ceil(Date.parse(row.start_timestamp) / 1000),
                                usernames: row.usernames.split('|'),
                                levels: row.levels.split('|'),
                                game_groups: row.game_groups,
                                matchmakingType: row.matchmaking_type.split('|').at(0),
                                gameVersions: row.game_versions,
                                platforms: row.platforms?.split('|'),
                                spectator: row.spectator,
                                ips: row.ips,
                                countries: row.countries?.split('|'),
                                host_server: row.host_server,
                                integration_names: row.integration_names?.split('|')
                            },
                        );
                        if (!!row.spectator && row.spectator.length > 3) {
                            this.spectatingSpectatorItems.push(
                                {
                                    col1: row.spectator,
                                    col2: timestampToHoursMinutesSeconds(Math.round((Date.now() - dbDateToMicroTimestamp(row.start_timestamp)) / 1000)),
                                    col3: row.game_versions,
                                    col4: 'SPECTATING',
                                }
                            )
                        }
                    }
                });
        },
        fetchLastMatches() {
            apiServiceVue.get(process.env.VUE_APP_GAME_DOMAIN + 'admin/matches/last')
                .then((res) => {
                    this.lastMatchesItems = [];
                    for (const row of res.data) {
                        this.lastMatchesItems.push(
                            {
                                started: Date.parse(row.start_timestamp) / 1000,
                                reasons: row.reasons,
                                usernames: row.usernames?.split('|'),
                                levels: row.levels?.split('|'),
                                battle_time: timestampToHoursMinutesSeconds(row.battle_time),
                                platforms: row.platforms?.split('|'),
                                game_versions: row.game_versions,
                                spectator: row.spectator,
                                game_groups: row.game_groups,
                                ips: row.ips,
                                countries: row.countries?.split('|'),
                                host_server: row.host_server,
                                integration_names: row.integration_names?.split('|'),
                                scrl_earned: row.scrl_earned,//?.split('/'),
                            },
                        );
                    }
                });
        },
        resetTimersDisplay() {
            this.timersResetSwitch = false;
            setTimeout(() => {
                this.timersResetSwitch = true;
            }, 10);
        },
        platformParse(platformFromDb) {
            const plat = {
                a: 'android',
                android: 'android',
                l: 'linux',
                linux: 'linux',
                w: 'windows',
                windows: 'windows',
            };

            return plat[platformFromDb] ?? '';
        }
    }
};
</script>

<style lang="scss" scoped>
h4, h5 {
    width: 100%;
    text-align: center;
}

.icon-os {
    width: 12px;
    margin-right: 2px;
}
</style>
