var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.integration_name
    ? _c("img", {
        staticClass: "integration-icon",
        attrs: {
          src: `/img/dashboard/${_vm.integration_name}-icon.png`,
          title: _vm.integration_name,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }