var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.country
    ? _c("img", {
        staticClass: "mini-flag",
        attrs: {
          src: _vm.flagImg(_vm.country, _vm.game_group),
          title: _vm.country,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }